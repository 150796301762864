import React, { useState, useRef } from "react";

import { useAuth } from '../hooks/useAuth'
import { LoginForm } from '../components/LoginForm'
import { RegisterForm } from '../components/RegisterForm'
import * as THREE from 'three';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

const animation = async (ref)=>{
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera( 75, 1, 1, 1000 );
  camera.position.set(550, 500, 0)
  camera.lookAt(0, 50, 0)
  const loader = new FontLoader();
  function loadFont(url) {
    return new Promise((resolve, reject) => {
       loader.load(url, resolve, undefined, reject)
    })
  }
  const loader3d = new GLTFLoader();
  function loadModel(url) {
    return new Promise((resolve, reject) => {
       loader3d.load(url, resolve, undefined, reject)
    })
  }
  const font = await loadFont('idk.typeface.json')
  const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
  const textGeometry = new TextGeometry("какие нахуй черепа", {
    font: font,
    size: 50,
    depth: 20,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  })
  textGeometry.center()
  const text = new THREE.Mesh(textGeometry, material)
  scene.add(text)
  text.position.set(0,350,0)
  text.rotation.set(0, 180, 0)

  // lada
  const lada = await loadModel('lada_2107.glb')
  lada.scene.scale.set(100,100,100)
  lada.scene.position.set(0,-100,0)
  scene.add(lada.scene);
  const light = new THREE.AmbientLight( 0xffeeff, 3); // soft white light
  scene.add( light );
  const pointLight = new THREE.PointLight(0xff55ff, 150000, 30000);
  pointLight.position.set(100, 200, 100)
  scene.add(pointLight)
  const pointLight1 = new THREE.PointLight(0xff44ff, 150000, 30000);
  pointLight1.position.set(-50, 200, -50)
  scene.add(pointLight1)

  camera.position.z = 5;
  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setSize( ref.current.clientWidth,  ref.current.clientHeight-5);
  renderer.setPixelRatio(window.devicePixelRatio);
  ref.current.appendChild( renderer.domElement );
  function rotateSmth(thing) {
    thing.rotation.x += 0.01;
    thing.rotation.y += 0.01;
    thing.rotation.z += 0.01;
  }
  function animate() {
    renderer.render( scene, camera );
    rotateSmth(lada.scene)
    text.rotation.y += 0.02;
  }
  renderer.setAnimationLoop( animate );
}

export const LoginPage = () => {
  const anim = useRef(null);
  animation(anim)


  const array = ['https://discord.ru'];
  const RussianRullete = () => {
    const randLink = array[Math.floor(Math.random() * array.length)];
    window.location = randLink;
  };
  return (
    <>
      <div className='flex flex-col h-full overflow-y-auto overflow-x-hidden pt-8'>
        <div className='flex m-auto max-w-screen-xl gap-20 justify-between'>
          <div className='flex flex-col justify-between'>
            <h2 className='text-6xl m-auto '>АЛЬДЗЗФAAA!!</h2>
            <div className="flex gap-6 w-full mx-auto">
              <LoginForm/>
              <RegisterForm/>
            </div>
            <button
              onClick={RussianRullete}
              className='text-3xl border-ctp-lavender m-auto leading-tight mb-4'
            >
              <h2 className='text-gradient from-ctp-flamingo to-ctp-pink font-bold'>
                Русская рулетка крутить бесплатно ТЫК
              </h2>
            </button>
          </div>
          <div className="flex justify-center m-auto"><iframe src="https://www.youtube.com/embed/MTLM8wgscaM?yt:stretch=16:9&vq=hd480&autoplay=1&loop=1&color=red&mute=1&iv_load_policy=3&rel=0&autohide=1&controls=0" width="225" height="463" allowtransparency="true" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" alt="Видео"></iframe></div>
        </div>
        <div ref={anim} className='flex flex-1 mx-auto w-full max-w-screen-md min-h-96 mt-4'>
        </div>
        <video src="https://89.22.236.52/stfu.mp4"></video>
      </div>
      <div>
      </div>
    </>
  )

}

export default LoginPage;
