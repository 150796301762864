import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from '../components/ErrorMessage'
import { useAuth } from '../hooks/useAuth'
import { updateUser } from '../utils/userService'

export const Profile = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const [userData, setUserData] = useState();
    const [profilePic, setProfilePic] = useState('');

    const get_profile = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.access_token,
        },
      };
      const response = await fetch('/api/me', requestOptions);

      let data = '';
      const body = await response.json()
      if (!response.ok) {
        console.log('why');
        if (response.status === 401) {
        }
        setErrorMessage(body.detail);
      } else {
        setUserData(body);
      }
    };

    useEffect(() => {
      get_profile();
    }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profilePic.startsWith('https://i.imgur.com/') && 
	profilePic.endsWith('.jpeg') || 
  profilePic.endsWith('.gif') ||
  profilePic.endsWith('.jpg') ||
  profilePic.endsWith('.png')
    ) {
      const a = await fetch(profilePic);
      if (!a.redirected) {
        const dct = {'profile_pic': profilePic, 'nickname': userData.nickname}
        const response = await updateUser(user.access_token, dct)
        if (response.ok) {
          userData.profile_pic = profilePic;
          setProfilePic('');
        }
      } else {
        setErrorMessage('фиговая ссылка или картинки не существует');
      }
    } else {
      if (profilePic) {
        setErrorMessage('фиговая ссылка или картинки не существует');
        setProfilePic('');
      }
    }
  };

  return (
    <>
      <div className='flex gap-2'>
        <img className='w-40 h-40 rounded-md object-cover' src={userData && (userData.profile_pic)} />
        <span className='text-2xl'>Nickname: {userData && ( userData.nickname)}</span>
      </div>
      <div className='my-2'>
        <h1 className='text-xl'>Смени автопортред</h1>
        <span>
          Должна быт ссылка на имгур типа https:/i.imgur.com/какая-тохуйня.jpeg
        </span>
      </div>
      <form className='w-1/2 flex gap-2' onSubmit={handleSubmit}>
        <input
          placeholder='Pic link'
          value={profilePic}
          onChange={(e) => setProfilePic(e.target.value)}
          className=''
          required
        />
        <button className='send-btn px-12' type='submit'>
          Update
        </button>
      </form>
      <ErrorMessage message={errorMessage} />
    </>
  );
};

export default Profile;
