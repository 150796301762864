import React, { useState } from "react";

export const SideBar = ({setSelectedSection}) => {
  return (
    <>
      <div className="flex flex-col w-1/6 min-w-52 h-full gap-4 p-4 bg-ctp-crust">
        <div className='flex flex-col pt-4 pb-4 text-center border-b-2 border-ctp-mauve'>
          <span className='block text-3xl mx-auto font-bold text-gradient from-ctp-red to-ctp-mauve leading-6 self-end'>
            <h1>ALT/SP3AK</h1>
          </span>
          <span className='block mx-auto'>Вебcайт для дураков</span>
        </div>
        <div className='flex flex-col gap-2 h-full'>
          <div onClick={()=>{setSelectedSection('hernya')}} className='sidebar-item font-light'>
            Hernya
          </div>
          <div onClick={()=>{setSelectedSection('MainChat')}} className='sidebar-item font-light'>
            Main Chat
          </div>
          <div onClick={()=>{setSelectedSection('VoiceChat')}} className='sidebar-item font-light'>
            Voice Chat 
          </div>

          <div onClick={()=>{setSelectedSection('Profile')}} className='sidebar-item mt-auto font-light'>
            Profile
          </div>
          <div onClick={()=>{setSelectedSection('Logout')}} className='sidebar-item font-light'>
            Logout
          </div>
        </div>
      </div>
    </>
  )

}

export default SideBar;
