import React from "react";

export const MessagePreview = ({msg}) => {
  const {nickname, profile_pic} = msg.author;
  return (
    <>
    <div className='flex gap-4'>
      <img 
        className='w-16 h-16 object-cover rounded-md'
        src={profile_pic}
        alt='just profile pic'
      />
      <div className='flex flex-col flex-1 justify-between overflow-hidden'>
        <div className='flex justify-between flex-1 border-b-2 border-ctp-mauve'>
          <span className='block text-lg h-fit mt-auto'>{nickname}</span> 
          <span className='block text-ctp-subtext0 h-fit text-sm mt-auto'>
            {msg.created_at}
          </span>
        </div>
          <p className='overflow-hidden text-ellipsis'>{msg.text}</p>
      </div>
    </div>
    </>
  );
};

export const Message = ({msg}) => {
  const {nickname, profile_pic} = msg.author;
  return (
    <>
    <div className='flex flex-1 gap-4'>
      <img 
        className='w-16 h-16 object-cover rounded-md'
        src={profile_pic}
        alt='just profile pic'
      />
      <div className='flex flex-col flex-1 h-fit gap-[10px]'>
        <div className='flex justify-between flex-1 border-b-2 border-ctp-mauve'>
          <span className='block text-lg h-fit mt-auto'>{nickname}</span> 
          <span className='block text-ctp-subtext0 h-fit text-sm mt-auto'>
            {msg.created_at}
          </span>
        </div>
          <p className='break-all'>{msg.text}</p>
      </div>
    </div>
    </>
  );
};

export default Message;
