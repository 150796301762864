import React, { useState } from "react";

import { useAuth } from '../hooks/useAuth'
import { LoginPage } from './LoginPage'
import { MainApp } from './MainApp'


const MainMainPage = () => {
  const { user } = useAuth();
  
  return (
    <>
      {user ? <MainApp/> : <LoginPage/>}
    </>
  )

}

export default MainMainPage;
