import React from 'react'

export const Blog = () => {
  return (
    <>
      <span className='text-lg'>камин сун</span>
      <img className='w-96' src='https://i.imgur.com/tiJUhHj.jpeg' />
    </>
  )

}
export default Blog
