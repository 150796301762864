import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorMessage } from '../components/ErrorMessage'
import { useAuth } from '../hooks/useAuth'
import {
  get_chat_messages, get_chat_members, format_msg_time,
  add_member_to_chat, kick_member_from_chat, get_role, updateChat
} from '../utils/chatService'
import Message from '../components/Message'


export const ChatPage = ( {chat, setSelectedChat, allMessages, setAllMessages, socket} ) => {
  const [message, setMessage] = useState("");
  const [members, setMembers] = useState([]);
  const [addNickname, setAddNickname] = useState('');
  const [role, setRole] = useState('member');
  const [isLoading, setIsLoading] = useState(true);
  const [isChatMembersShow, setIsChatMembersShow] = useState(true);
  const [chatPic, setChatPic] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const bottomRef = useRef(null);
  const { id, title, cover } = chat;
  
  const handleMessageSend = async (e) => {
    e.preventDefault();
    const msg_obj = {'text': message, 'chat_id': chat.id}
    socket.current.emit('new_message', msg_obj)
    setMessage('');
  };

  const render_chat_messages = async () => {
    if (!allMessages.get(id)) {// && allMessages.get(id).length <= 1) {
      const response = await get_chat_messages(
        user.access_token, chat.id
      );
      const body = await response.json();
      if (!response.ok) {
        if (response.status === 401) {
          logout();
        }
        setErrorMessage(body.detail);
      } else {
        body.map((msg, idx) => {
          format_msg_time(msg);
        });
        setAllMessages((prevMap) => new Map(prevMap.set(id, body)))
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  };

  const render_chat_members = async () => {
    const response = await get_chat_members(user.access_token, chat.id);
    const body = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      if (response.status === 403) {
        setSelectedChat(null);
      }
      setErrorMessage(body.detail);
    } else {
      setMembers(body);
    }
  };

  const add_member = async (e) => {
    e.preventDefault();
    const response = await add_member_to_chat(user.access_token, chat.id, addNickname);
    const body = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      setErrorMessage(body.detail);
    } else {
      await render_chat_members();
    }
  };

  const kick_member = async (kickNickname) => {
    const response = await kick_member_from_chat(user.access_token, chat.id, kickNickname);
    const body = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      setErrorMessage(body.detail);
    } else {
      await render_chat_members();
    }
  };

  const get_get_role = async () => {
    const response = await get_role(user.access_token, chat.id);
    const a = await response.json();
    setRole(a);
  };

  const toggle_members = async() => {
    setIsChatMembersShow(isChatMembersShow => !isChatMembersShow);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (chatPic.startsWith('https://i.imgur.com/') && chatPic.endsWith('.jpeg')) {
      const a = await fetch(chatPic);
      if (!a.redirected) {
        const dct = {'cover': chatPic, 'title': chat.title}
        const response = await updateChat(user.access_token, chat.id, dct)
        if (response.ok) {
          chat.cover = chatPic;
          setChatPic('');
        }
      }
    } else {
      if (chatPic) {
        setErrorMessage('фиговая ссылка или картинки не существует');
        setChatPic('');
      }
    }
  };

  useEffect(() => {
    (async () => {
      await render_chat_messages();
      await render_chat_members();
      await get_get_role();
    })();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [allMessages]);

  return (
    //<button className='send-btn px-12' onClick={() => {setSelectedChat(null)}}>Назад</button>
    <>
      <div className='flex'>
        <img src={cover} alt="chat pic" className='object-cover rounded-md w-40 h-40' />
        <h1 className='text-3xl ml-6'>{title}</h1>
      </div>
      {role === 'owner' &&
        <form className='flex gap-2 mt-4' onSubmit={handleSubmit}>
          <input
            placeholder='New chat pic link on imgur'
            value={chatPic}
            onChange={(e) => setChatPic(e.target.value)}
            className=''
            required
          />
          <button className='send-btn px-12' type='submit'>
            Update
          </button>
        </form>
      }
      <div>
        <ErrorMessage message={errorMessage} />
      </div>
      <div className='flex flex-col gap-2 py-2'>
        <button onClick={toggle_members} className='w-fit text-xl'>-&gt;Chat membersO_o&lt;-</button>
        <div className={isChatMembersShow ? 'hidden' : 'flex flex-col gap-4 ml-8 pl-4 border-l-2 border-ctp-mauve'}>
          {members.map((member, idx) => (
            <div className='flex' key={idx}>
              <img className='w-16 min-w-16 h-16 min-h-16 rounded-md' src={member.profile_pic} alt='just profile pic'/>
              <div className='flex flex-col justify-between pl-4'>
                <span>{member.nickname}</span>
                {role === 'owner' &&
                  <button className='text-left' onClick={() => {kick_member(member.nickname)}}>kick</button>
                }
              </div>
            </div>
          ))}
          {role === 'owner' &&
              <form className='mt-4' onSubmit={add_member}>
                <span className='text-xl text-center pl-1'>Добавить дебила</span>
                <div className='flex gap-2'>
                  <input
                    placeholder='Никнейм'
                    value={addNickname}
                    onChange={(e) => setAddNickname(e.target.value)}
                    className='border-2 w-3/4'
                    required
                  />
                  <button className='send-btn w-1/4' type='submit' >
                    Добавить
                  </button>
                </div>
              </form>
          }
        </div>
      </div>
      <div className='flex flex-col gap-4 my-6 pr-4 overflow-y-auto max-h-[45dvh]'>
        {isLoading ? (
          <div>loading...</div>
        ) : (
          allMessages.get(id) &&
          allMessages.get(id).map((msg, idx) => (
            <Message msg={msg} key={idx}/>
          ))
        )}
        <div ref={bottomRef} />
      </div>
      <form className='flex gap-2 bottom-4 left-4' onSubmit={handleMessageSend}>
        <input
          placeholder='Сообщение'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className='border-2 w-3/4'
          required
        />
      <button className='send-btn' type='submit'>
        Отправить
      </button>
      </form>
    </>
  );
};

export default ChatPage;
