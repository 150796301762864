import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Peer from "peerjs"; 

import { useAuth } from '../hooks/useAuth'


const getAudio = () => {
  return navigator.mediaDevices.getUserMedia({ audio: true, video: false })
}

let userId = '', myStream = null, peer = null, peers = [], receivedCalls = new Map();
let peerOptions = {
  host: '/',
  path: 'peer',
  iceServers: [{
      urls: [
        'stun:stun.1.google.com:19302',
        'stun:stun1.1.google.com:19302'
      ]
  }],
}


export const VoiceChat = ({usersInVoice, socket}) => {
  const { user, logout } = useAuth();
  const [isJoined, setIsJoined] = useState(false);
  const [peerStatus, setPeerStatus] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [temp, setTemp] = useState([]);
  const [myMeasureInterval, setMyMeasureInterval] = useState(0);
  const [volumeMap, setVolumeMap] = useState(new Map());


  const onReceiveAudioStream = (stream) =>{ 
    console.log("receiving an audio stream"); 
    const audio = document.createElement('audio');
    audio.srcObject = stream;
    audio.addEventListener('loadedmetadata', () => {
      audio.play()
    })
  }

  const measureVolume = (id, stream) => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);

    analyser.smoothingTimeConstant = 0.5;
    analyser.fftSize = 1024;
    microphone.connect(analyser);

    const measure = setInterval(() => {
      if (!stream.active) {
        console.log('clearing', stream)
        clearInterval(measure);
      }
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
          sum += array[i];
      }
      const average = sum / array.length;
      setVolumeMap(new Map(volumeMap.set(id, average)))
      console.log(id, stream)
    }, 500);
    return measure
  }
useEffect(()=>{
      console.log(volumeMap)
}, [volumeMap])

useEffect(()=>{
if (isJoined) {
  socket.current.emit('joinVoice')
  getAudio().then(mystream=>{
    myStream = mystream;
    myStream.getAudioTracks()[0].enabled = !isMuted;
    setMyMeasureInterval(measureVolume(socket.current.id, mystream))

    peer = new Peer(socket.current.id, peerOptions)
    console.log(peer);
    if (peer.destroyed) {
      peer = new Peer(socket.current.id, peerOptions)
      console.log('new peer')
    }

    peer.on('call', (call)=>{
      console.log("call receiving")
      console.log(myStream);
      console.log(call.peer, usersInVoice)
      call.answer(myStream); 
      call.on('stream', (stream)=>{
          onReceiveAudioStream(stream); 
          receivedCalls.set(call.peer, stream); 
          measureVolume(call.peer, stream)
          console.log('receivedcalls', receivedCalls)
      });
    });

    peer.on('open', (id) => {
      setPeerStatus(true);
      console.log("connected to peerserver");

      // won't call myself 
      console.log('im', socket.current.id)
      const otherUsersInVoice = (usersInVoice).filter(x=>x[0] !== socket.current.id);  
      console.log(otherUsersInVoice)

      peers = (otherUsersInVoice).map((u) => {
        //call everyone already present 
        var mediaConnection = peer.call(u[0], myStream); 
        console.log(`Calling ${u[1].nickname}`);

        const audio = document.createElement('audio');
        mediaConnection.on('stream', (stream)=>{
          console.log(`${u[1].nickname} picked up call`, stream)
          measureVolume(u[0], stream)
          audio.srcObject = stream
          audio.addEventListener('loadedmetadata', () => {
            audio.play()
          })
        });

        // if anyone closes media connection 
        mediaConnection.on('close',()=>{
            audio.remove();
        })
        return mediaConnection; 
      });
      console.log('users-peers', otherUsersInVoice, peers)
    });
  })
} else {
  if (myMeasureInterval) {
    clearInterval(myMeasureInterval)
    setMyMeasureInterval(0)
  }
  if (peer) {
    peer.destroy();
    console.log('peer destroying', peer);
  }
  setPeerStatus(false)}
},[isJoined])

const onJoinButton = async ()=>{
if (isJoined) {
  console.log('isJoined leave voice')
  socket.current.emit('leaveVoice')
}
setIsJoined(!isJoined)
}
useEffect(()=>{
if (myStream) {
  const track = myStream.getAudioTracks()[0];
  track.enabled = !isMuted;
  console.log(track.enabled, isMuted)
}
}, [isMuted])

const onMuteButton = async ()=>{
setIsMuted(!isMuted);
}


return (
<>
  <h1 className='text-3xl mb-4 text-center'>Воис</h1>
  <div className='flex justify-between'>
    <p className='text-lg my-auto'>Соединение: {peerStatus ? '👌' : '❌'}</p>
    <button className='send-btn w-1/4' onClick={onJoinButton}>
      {isJoined ? 'leave' : 'join'}
    </button>
  </div>
  <div className='flex flex-col gap-4 mt-10 p-4 bg-ctp-base shadow-md rounded-md min-h-44'>
    {usersInVoice.map((user, idx)=>
      <div className='flex' key={idx}>
        <img 
          className='w-16 h-16 object-cover rounded-md'
          src={user[1].profile_pic}
          alt='just profile pic'
        />
        <div className='flex flex-1 ml-4 justify-between border-b-2 border-ctp-mauve'>
          <span className='flex-end text-xl'>{user[1].nickname}</span> 
          {volumeMap.get(user[0]) > 11 && <span className='text-rose-500'>ON AIR</span>}
        </div>
      </div>
    )}
  </div>
  <div className='flex flex-1 justify-between'>
    <span className='text-lg my-auto'>micro: {isMuted ? 'muted': 'speak mf'}</span>
    <button className='send-btn w-1/4' onClick={onMuteButton}>mute</button>
  </div>
</>
);
};

export default VoiceChat
