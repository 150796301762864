import React from "react";

import MessagePreview from '../components/MessagePreview'

export const ChatInList= ({chat, setSelectedChat, allMessages}) => {
  const {title, cover} = chat;
  let lastMsg = null;
  let chatMsgHistory = allMessages.get(chat.id)
  if (chatMsgHistory && chatMsgHistory[chatMsgHistory.length - 1]) {
    lastMsg = chatMsgHistory[chatMsgHistory.length - 1]
  }

  return (
    <div
      className='
        flex flex-1 gap-4 bg-ctp-base rounded-md py-4 px-6 shadow-md outline-2 
        outline-offset-2 outline-ctp-mauve hover:cursor-pointer transition 
        hover:shadow-md duration-500
      '
      onClick={() => {setSelectedChat(chat)}}
    >
      <img
        src={cover}
        className='
          object-cover w-32 h-32 rounded-3xl border-ctp-mantle
        '
        alt="chat pic"
      />
      <div className='flex flex-col flex-1 justify-between overflow-hidden'>
        <div className='text-center'>
          <h2 className='text-2xl border-b-2 border-ctp-mauve px-2 pb-2'>{title}</h2>
        </div>
        <div className='px-4'>
          {lastMsg && <MessagePreview msg={lastMsg}/>}
        </div>
      </div>
    </div>
  );
};
export default ChatInList;
