import React, { useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client';
import SideBar from "../components/SideBar"
import Profile from "./Profile"
import VoiceChat from "./VoiceChat"
import ChatPage from './ChatPage';
import Blog from './Blog'
import { useAuth } from '../hooks/useAuth';
import { get_chat_messages, format_msg_time } from "../utils/chatService";

const playUserChangeSound = () => {
  const audio = document.createElement('audio');
  console.log('playing sound')
  audio.src = 'useradd.wav';
  audio.addEventListener('loadedmetadata', () => {
    audio.play()
  })
}

const mainChat = {id: "7f07ee85-1660-4312-a4f0-fcdc1f02d5b8", title: "Main Chat", cover: "https://i.imgur.com/aVTgWXd.jpeg"};

export const MainApp = () => {
  const { logout, user } = useAuth();
  const [selectedSection, setSelectedSection] = useState('hernya');
  const [mainElement, setMainElement] = useState('');
  const socket = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [allMessages, setAllMessages] = useState(new Map());
  const [usersInVoice, setUsersInVoice] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState();

  const [isJoined, setIsJoined] = useState(false);
  const [peerStatus, setPeerStatus] = useState(false);
  const [otherVideos, setOtherVideos] = useState(new Map());
  const myStream = useRef();
  const peer = useRef();
  const peers = useRef([]);
  const receivedCalls = useRef(new Map());


  useEffect(()=>{
    socket.current = io(
      { transports: ['websocket'], path: '/sockets', auth: user.access_token }
    )
    socket.current.on('connect', ()=>{
      socket.current.send('123456')
      setIsLoading(false)
      console.log('socket', socket.current)
    })
    socket.current.on('connect_error', err => {
      if (err.message == 'auth failed') logout()
    })
    socket.current.on('new_message', msg => {
      msg = JSON.parse(msg)
      format_msg_time(msg);
      //setArrivalMessage(msg);
      let history = allMessages.get(mainChat.id)
      if (!history) {
        history = []
      }
      setAllMessages(
        (prev)=>new Map(prev.set(mainChat.id, [...history, msg]))
      )
      console.log('msg', msg)
    })
    socket.current.on('addUsersInVoice', (users) => {
      setUsersInVoice(prev=>users)
    });
    socket.current.on('addUserInVoice', (user) => {
      playUserChangeSound()
      console.log('socket add user', user)
      setUsersInVoice(prev=>[...prev, user])
    });
    socket.current.on('removeUserInVoice',(user)=>{
      playUserChangeSound()
      setUsersInVoice(
        usersInVoice=>usersInVoice.filter(x=>x[1].nickname !== user.nickname)
      ); 
    });
  }, [])

  useEffect(()=>{
    switch (selectedSection) {
      case "Logout":
        logout();
        break;
      case "Profile":
        setMainElement(<Profile/>);
        break;
      case "VoiceCha":
        setMainElement(<VoiceChat usersInVoice={usersInVoice} socket={socket} isLoading={isLoading}/>);
        break;
    }
  }, [selectedSection])
  useEffect(()=>{console.log('isJoined', isJoined)}, [isJoined])

  return (
    <>
      <div className='flex w-full h-dvh'>
        <SideBar setSelectedSection={setSelectedSection}/>
        <div className='flex flex-col flex-1 overflow-auto p-8'>
          {selectedSection=='hernya' ? <Blog/> : ''}

          {selectedSection=='MainChat' ? <ChatPage chat={mainChat} socket={socket} allMessages={allMessages} setAllMessages={setAllMessages}/> : ''}

          {selectedSection=='VoiceChat' ? <VoiceChat usersInVoice={usersInVoice} socket={socket} isLoading={isLoading} myStream={myStream} peer={peer} peers={peers} receivedCalls={receivedCalls} peerStatus={peerStatus} setPeerStatus={setPeerStatus} isJoined={isJoined} setIsJoined={setIsJoined} otherVideos={otherVideos} setOtherVideos={setOtherVideos}/> : ''}

          {selectedSection=='Profile' ? <Profile/> : ''}
        </div>
      </div>
    </>
  );
}

export default MainApp;
