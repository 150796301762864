import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from "../components/ErrorMessage"
import { login, signup } from '../utils/userService'


export const RegisterForm = () => {
    const navigate = useNavigate();
    const [nickname, setNickname] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!nickname.trim() || !password.trim()) {
        setErrorMessage('Ник или пароль пустой');
        return
      }
      const response = await signup(nickname, password);
      const data = await response.json();
      if (response.ok) {
        setErrorMessage('');
        setSuccessMessage('Успешный успех')
      } else {
        setErrorMessage(data.detail);
      };
    }

    return (
      <>
        <form onSubmit={handleSubmit} className='flex flex-1 flex-col gap-4 mx-auto my-8'>
          <h1 className='text-center text-3xl whitespace-nowrap'>Зарегаца :3</h1>
          <input
            placeholder='логин'
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className=''
            required
          />
          <input
            type='password'
            placeholder='пароль'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button 
            className='send-btn mt-2'
            type='submit'
          >
            Хуяк
          </button>
          <div>
            <ErrorMessage message={errorMessage} />
          </div>
          <div className='text-md text-green-600'>
            {successMessage}
          </div>
        </form>
      </>
    );
};

export default RegisterForm;
